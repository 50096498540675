<template>
  <div>
    <el-card class="card">
      <h1>i quote</h1>
      <p>
        The second continent after Eurasia. It covers an area of 29.2 million
        square kilometers (including 30.2 million square kilometers of islands).
        The total population is 469 million (1980). The terrain is dominated by
        terraced high plains, plateaus and Taiyuan, and there are broad tectonic
        basins in the hinterland. The longest river is the Nile. There are also
        Congo River, Niger River, Senegal River, Gambia River, Orange River,
        etc. The main type of vegetation is tropical sparsegen<span
          >more...</span
        >
      </p>
      <div class="name">
        <img class="fl" src="@/assets/Ellipse_81.png" />
        <div class="fl">
          <h2>user name</h2>
          <div class="africa">
            <i class="el-icon-location-information"></i>
            <span class="span1">Africa</span>
            <span class="span2">Release time: December 25, 2021</span>
          </div>
        </div>
        <div class="clear"></div>
      </div>
    </el-card>
    <el-card class="card mt">
      <h1>i quote</h1>
      <p>
        The second continent after Eurasia. It covers an area of 29.2 million
        square kilometers (including 30.2 million square kilometers of islands).
        The total population is 469 million (1980). The terrain is dominated by
        terraced high plains, plateaus and Taiyuan, and there are broad tectonic
        basins in the hinterland. The longest river is the Nile. There are also
        Congo River, Niger River, Senegal River, Gambia River, Orange River,
        etc. The main type of vegetation is tropical sparsegen<span
          >more...</span
        >
      </p>
      <div class="name">
        <img class="fl" src="@/assets/Ellipse_81.png" />
        <div class="fl">
          <h2>user name</h2>
          <div class="africa">
            <i class="el-icon-location-information"></i>
            <span class="span1">Africa</span>
            <span class="span2">Release time: December 25, 2021</span>
          </div>
        </div>
        <div class="clear"></div>
      </div>
    </el-card>
    <el-card class="card mt">
      <h1>i quote</h1>
      <p>
        The second continent after Eurasia. It covers an area of 29.2 million
        square kilometers (including 30.2 million square kilometers of islands).
        The total population is 469 million (1980). The terrain is dominated by
        terraced high plains, plateaus and Taiyuan, and there are broad tectonic
        basins in the hinterland. The longest river is the Nile. There are also
        Congo River, Niger River, Senegal River, Gambia River, Orange River,
        etc. The main type of vegetation is tropical sparsegen<span
          >more...</span
        >
      </p>
      <div class="name">
        <img class="fl" src="@/assets/Ellipse_81.png" />
        <div class="fl">
          <h2>user name</h2>
          <div class="africa">
            <i class="el-icon-location-information"></i>
            <span class="span1">Africa</span>
            <span class="span2">Release time: December 25, 2021</span>
          </div>
        </div>
        <div class="clear"></div>
      </div>
    </el-card>
    <el-card class="card mt">
      <h1>i quote</h1>
      <p>
        The second continent after Eurasia. It covers an area of 29.2 million
        square kilometers (including 30.2 million square kilometers of islands).
        The total population is 469 million (1980). The terrain is dominated by
        terraced high plains, plateaus and Taiyuan, and there are broad tectonic
        basins in the hinterland. The longest river is the Nile. There are also
        Congo River, Niger River, Senegal River, Gambia River, Orange River,
        etc. The main type of vegetation is tropical sparsegen<span
          >more...</span
        >
      </p>
      <div class="name">
        <img class="fl" src="@/assets/Ellipse_81.png" />
        <div class="fl">
          <h2>user name</h2>
          <div class="africa">
            <i class="el-icon-location-information"></i>
            <span class="span1">Africa</span>
            <span class="span2">Release time: December 25, 2021</span>
          </div>
        </div>
        <div class="clear"></div>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.card h1 {
  margin-left: 20px;
  font-size: 18px;
  color: #ffc55f;
}
.card p {
  margin-left: 20px;
  font-size: 13px;
  line-height: 15px;
  margin-top: 10px;
}
.card span {
  font-size: 13px;
  color: #ff0a0a;
}
.name img {
  margin-top: 25px;
  margin-left: 20px;
}
.name h2 {
  font-size: 20px;
  margin-top: 17px;
  margin-left: 30px;
}
.africa i {
  margin-left: 30px;
  margin-top: 10px;
}
.africa .span1 {
  font-size: 13px;
  color: #000;
}
.africa .span2 {
  font-size: 13px;
  color: #000;
  margin-left: 43px;
}
</style>